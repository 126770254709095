import Card from 'react-bootstrap/Card';
import {NavLink, useParams} from 'react-router-dom';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import { TrackingItemProps } from './interfaces/ITrackings';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import classes from './style/TrackingCard.module.css'
import {useTranslation} from 'react-i18next';
import CircleNumber from '../../UI/circle-progress/CircleNumber';
import ShareIcon from '../../components/share-icon/ShareIcon';
import moment from 'moment';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import PopoverUi from './components/PopoverUi';


function InitiativeDetailsCard({tracking,setShowAssignUsers}: TrackingItemProps  ) {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('https://championsacademy.page.link');

  let { id } = useParams()

  useEffect(() => {
    if( tracking ){
      createDynamicLink()
    }
  }, [tracking]);
  

  const createDynamicLink = async () => {
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://championsacademy.page.link',
        link: `${getDynamicLinkDomain()}/?type=Initiative&model_id=${id}&invitation_id=${tracking?.user_tracking_enrollment_id}`,
        androidInfo: {
          androidPackageName: 'com.championsacademy',
        },
        iosInfo: {
          iosBundleId: 'org.Champions.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBlnJ9M_bXXKck16qjoInYIRo0xFp5pPPU`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  return (
    <>
    
      <div className='d-flex justify-content-between align-items-center share-dropdown mb-1'>
        <div className='d-flex align-items-center'>
        </div>

        
        
        <div className='d-flex align-items-center cursor-pointer'>

        {/*show assign users*/}
        
        <div
        onClick={() => {
          if(setShowAssignUsers){
            setShowAssignUsers(true)
          }
          } }
              style={{
                padding: '5px 15px 5px 15px',
                border: '1px solid #E1E1E1',
                borderRadius: '6px',
                margin: '5px',
              }}
              className="d-flex justify-content-between align-items-center mb-3 mt-3">
              <div>
                <img
                  src={tracking?.selected_user_avatar ?? '/icons/manHolder.png'}
                  style={{
                    width: '30px',
                    height: '30px',
                    margin: '0 10px',
                    borderRadius: '50%',
                  }}
                />
                <span>{tracking?.selected_user_name}</span>
                {/*
                <p style={ {color: 'red'  }}>switch to another family user</p>
                */}
              </div>
              <div>
                <img src='/down_small_fill.svg' alt="img" />
              </div>
              
        </div>
        
        {/*show assign users*/}

        <div
        style={{
          padding: '7px 10px',
          border: '1px solid #E1E1E1',
          borderRadius: '6px',
          margin: '5px',
        }}
        >
            <PopoverUi name={tracking?.selected_user_name} />
        </div>
       
          <ShareIcon url={url} hasInvite={true} />
         
        <img src="/more.svg" className='cursor-pointer' onClick={()=>setShowModal(true)} />

        


        
        </div>
        
      </div>

      {/*  
      <div className='d-flex align-items-center mb-md-5'>
        <img src="/calendar_line.svg" alt='img' /> 
        <p className='card-custom-date'>{moment(tracking?.start_date).format('DD MMM, YYYY')} - {moment(tracking?.end_date).format('DD MMM, YYYY')}</p>
      </div>
      */}

     

     
      
      <Card className="text-center" style={{ background: '#F4F4F4' , border: 'none' }}>
        
        <Card.Body className="mt-3 pt-md-5 pb-md-5"> 
          <Card.Title>
            <div className="d-flex align-items-center" style={{ justifyContent: 'space-evenly' }} >
            { tracking?.has_invitation_calculation ? 
              <div className="d-flex justify-content-center align-items-center flex-column">
              <span>
                  <CircleProgress
                    value={
                      tracking?.total_progress % 1 === 0
                        ? tracking?.total_progress.toFixed(0)
                        : tracking?.total_progress.toFixed(1)
                    }
                    progressColor='#008993'
                    background='#e1f4ff'
                    titleColor='#008993'
                    
                  />
              </span>
                <span style={{fontSize: '15px', margin: '20px 5px',color:'#000' , fontWeight: '500'}}>
                  {t('TotalScore')}
                </span>
                
              </div>
              : '' }
             

              <div className="d-flex justify-content-center align-items-center flex-column">
              <span>
                  <CircleProgress
                    value={
                      parseFloat(tracking?.activity_progress) % 1 === 0
                        ? parseFloat(tracking?.activity_progress).toFixed(0)
                        : parseFloat(tracking?.activity_progress).toFixed(1)
                    }
                    progressColor='#e37e08'
                    background='#fff4e8'
                    titleColor='#e37e08'
                  />
              </span>
                <span style={{fontSize: '15px', margin: '20px 5px',color:'#000' , fontWeight: '500'}}>
                  {t('Activities')}
                </span>
                
              </div>

              <div className="d-flex justify-content-center align-items-center flex-column">
              <span>
                  <CircleProgress
                    value={
                      parseInt(tracking?.invitation_progress) % 1 === 0
                        ? parseInt(tracking?.invitation_progress).toFixed(0)
                        : parseInt(tracking?.invitation_progress).toFixed(1)
                    }
                    progressColor='#ca1e20'
                    background='#ffeff0'
                    titleColor='#ca1e20'
                  />
              </span>
                <span style={{fontSize: '15px', margin: '20px 5px',color:'#000' , fontWeight: '500'}}>
                  {t('Invitations')}
                </span>
                
              </div>

              <div className="d-flex justify-content-center align-items-center flex-column">
              <span>
                  <CircleNumber
                    value={tracking?.invitation_count ?? 0 }
                    inviteNumber={tracking?.invitation_max_count ?? 0 }
                    progressColor='#9e13bc'
                    background='#fdf4ff'
                    titleColor='#9e13bc'
                  />
              </span>
                <span style={{fontSize: '15px', margin: '20px 5px',color:'#000' , fontWeight: '500'}}>
                  {t('Invitations Number')}
                </span>
                
              </div>
            </div>
          </Card.Title>
            
        </Card.Body>
      </Card>

      {/*assign user pop up*/}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>

          { tracking?.has_invitation_calculation == 1 ? 
          <div className='mb-3'>
            <h5 className='main-color'>Total</h5>
            <p>
            The total percentage of the competition consists of the percentage of invitations plus the percentage of activities
            </p>        
          </div>
          : '' }

          <div className='mb-3'>
            <h5 className='main-color'>Activities</h5>
            <p>
            Activities This is the percentage of activities you performed on all days of the competition
            </p>        
          </div>

          <div className='mb-3'>
            <h5 className='main-color'>Invitations</h5>
            <p>
            Invitations This is the percentage of your invitations compared to the highest number made by a participant in this competition
            </p>        
          </div>

          { tracking?.has_invitation_calculation == 1 ?    
          <div className='mt-3'>
            <p>The relative weight of activities: {100 - tracking?.invitation_percentage}% of the total competition total</p>
            <p>The relative weight of invitations: {tracking?.invitation_percentage}% of the total competition total
            </p>
          </div>
          : '' }

          <div className='invite-desc mt-4'>
          {
            tracking?.invitation_count == 0
            ? 'The competition is waiting for your friends! Do not forget to invite them.'
            : tracking?.invitation_count == tracking?.invitation_max_count
            ? `You have invited ${
                tracking?.invitation_max_count
              } friends, which is the most so far in this competition! Keep up the good work`
            : `You have invited ${tracking?.invitation_count} from your friends, so far. But watch out! Another competitionr has invited
               ${tracking?.invitation_max_count
              } friends, which is the current highest number of invitations in this competition.Can you beat them? Invite more friends and claim the top spot`
          }
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setShowModal(false)} className="w-100">
            <CancelButton text={t('Cancel')} />
          </div>
        </Modal.Footer>
      </Modal>

      
    </>
  );
}

export default InitiativeDetailsCard;
