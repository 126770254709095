import {useEffect, useRef, useState} from 'react';
import classes from '../style/ActivitiesTab.module.css';
import {Card, Col, Container, Row} from 'react-bootstrap';
import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {FaCheckSquare, FaMarker, FaRegCheckSquare} from 'react-icons/fa';
import moment from 'moment';
import {getDaysInRange} from '../../../helpers/getDayInRange';
import {TrackingItemProps} from '../interfaces/ITrackings';
import {useLocation, useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../../UI/main-button/MainButton';
import {updateProgress} from '../Initiatives.service';
import {useTranslation} from 'react-i18next';
//import 'moment/locale/ar';

const ActivitiesTab = ({
  tracking,
  daySelected,
  userId,
  setDaySelected,
}: TrackingItemProps) => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const days = getDaysInRange([
    {
      start: moment(tracking.start_date).locale('en'),
      end: moment(tracking.end_date).locale('en'),
    },
  ]);

  const trackingActivities = tracking.activities || [];

  const dateToday = moment().format('YYYY-MM-DD');

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const [show, setShow] = useState<boolean>(false);

  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [userActivityId, setUserActivityId] = useState(null);

  const [activeDay, setActiveDay] = useState(daySelected);

  const today = moment().locale('en').format('YYYY-MM-DD');

  const handleClick = (e: any) => {
    const bar = e.currentTarget;

    const clickPosition = e.clientX - bar.getBoundingClientRect().left;

    const totalWidth = bar.clientWidth;
    let newProgress = (clickPosition / totalWidth) * 100;
    if (newProgress >= 95) {
      newProgress = 100;
    }
    setProgress(Math.floor(newProgress));
    if (newProgress >= 90) {
      setStyleProgress(85);
    } else {
      setStyleProgress(Math.floor(newProgress));
    }
  };

  const updateManualProgress = async () => {
    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', String(Math.floor(progress)));
    let result = await updateProgress(data);

    if (result.response.status) {
      setShow(false);
      if (setDaySelected) setDaySelected(daySelected!);
    }
  };
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    console.log('check history in track' , tracking )
    setTimeout(() => {
      ScrollTo(null);
    }, 1000);
  }, []);

  const ScrollTo = (day: string | null) => {
    let startDate = moment(tracking.start_date).locale('en');
    const indexToScroll =
      Math.abs(startDate.diff(moment(day ? day : daySelected), 'days')) + 2;

    if (containerRef.current) {
      const elementToScrollTo = containerRef.current.children[
        indexToScroll
      ] as HTMLElement;
      elementToScrollTo.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  };

  const hasNoScoreEqualOne = (activities:any) => {
    return activities?.some((activity:any) => activity?.no_score === 0);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trackType = useState(queryParams.get('trackType') || null);

  return (
    <>
      <Container>
        <div ref={containerRef} className="d-flex mt-4 hideScroll">
          {days.map((d, i) => {
            return (
              <div
                className={`${classes.Day} ${
                  d.date == daySelected ? classes.ActiveDay : ''
                }`}
                onClick={() => {
                  if (setDaySelected) {
                    setActiveDay(d.date);
                    setDaySelected(d.date);
                  }
                  ScrollTo(d.date);
                }}>
                <div>{d.dayName}</div>
                
                <div className='act-day-number'>
                  {moment(d.date).format(daySelected === d.date ? 'D' : 'D')}
                </div>

                <div 
                className={`act-day-name ${
                  d.date == daySelected ? 'active' : ''
                }`}
                >
                  {moment(d.date).locale('ar').format('ddd')}
                </div>
              </div>
            );
          })}
        </div>

        <Row>
        <Col md="12" className="mt-3 mb-0">
        
        {
          trackingActivities?.length !== 0 && hasNoScoreEqualOne(trackingActivities)
          ?

          <h3 className="points-title">Today Pionts <span className="points-val">({tracking.daily_progress})% </span></h3>

          :
          ''

        }


            
          </Col>
          {/*
          <Col md="12" className="mt-5 mb-5">
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{padding: '1rem 2rem'}}>
                <div style={{fontSize: '22px'}}>{t('DailyScore')}</div>
                <div>
                  <CircleProgress value={tracking.daily_progress} />
                </div>
              </div>
            </Card>
          </Col>
          */}

          {trackingActivities.map((a: any, i) => {
            return (
              <Col
                md="12"
                className="mt-4"
                key={i}
                onClick={() => {
                  if(  moment(daySelected).isAfter(dateToday) ){
                    setShowErrorMsg(true)
                  }else{
                    let url = `/activity/${a.activity_id}/${a.tracking_id}/${dateToday}/${userId}/${daySelected}/add-progress?lang=${a.lang}`
                    if( tracking.tracking_enrollment_history_id ){
                      url += `&tracking_enrollment_history_id=${tracking.tracking_enrollment_history_id}`
                    }
                    if( trackType != null ){
                      url += `&trackType=initiative`
                    }
                    navigate(
                      url ,
                      {
                        state: {
                          item: tracking,
                          daySelected: daySelected,
                        },
                      },
                    );
                }
                }}>
                  
                 
                <Card
                  style={{
                    borderColor: '#e9e9e9',
                    padding: '1rem 2rem',
                    cursor: 'pointer',
                  }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>
                        <span
                          style={{
                            fontWeight: '600',
                            fontSize: '18px',
                           color:'#4F4F4F'
                          }}>
                          {a.name}
                        </span>
                      </div>

                      { a.end_date && moment(a.end_date).isSameOrAfter(dateToday) ? 
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '300',
                          color: '#787878',
                          marginTop: '10px',
                        }}
                        className={`${
                          moment(a.end_date).isSame(today)
                            ? 'secondry-color'
                            : ''
                        } font-weight-bold`}>
                        {t('End Date')}:{' '}
                        {moment(a.end_date).isSame(today)
                          ? t('Today')
                          : a.end_date}
                      </div>
                      : '' }

                      { a.end_date && moment(a.end_date).isBefore(dateToday) ? 
                      <div className='secondry-color mt-1'>This activity has finished</div>
                      : '' }

                      { a?.date_type == 'date_range' && trackType != null  && !moment(daySelected).isSame(a?.first_day) ? 
                      <div className='text-danger'>
                        This activity moved from day { a?.first_day }
                      </div>
                      : '' }

                    </div>
                    {a.auto_progress_update == 0 && today == daySelected ? (
                      <div
                        style={{
                          padding: '5px 15px',
                          borderRadius: '7px',
                          backgroundColor: '#fee',
                          color: '#FA5456',
                          cursor: 'pointer',
                          display: 'none',
                        }}
                        onClick={() => {
                          setUserActivityId(a.user_activity_id);
                          setShow(true);
                        }}>
                        Update
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='d-flex align-items-center'>
                    
                    <div
                      onClick={() => {
                        navigate(
                          `/tracking-system-activity/${a.activity_id}/${a.tracking_id}/${a.end_date}/${userId}/add-progress/`,
                          {
                            state: {
                              item: tracking,
                            },
                          },
                        );
                      }}>
                      {!a.no_score && <CircleProgress ShowStroke={true} value={a.progress} /> }
                    </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>

      {/*Update Progress manually*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '10px',
              backgroundColor: '#ccc',
              position: 'relative',
              borderRadius: '15px',
              cursor: 'pointer',
              marginTop: '25px',
            }}>
            <span
              className={classes.ProgressValue}
              style={{
                color: '#fff',
                background: '#FA5456',
                borderBottom: '10px solid #FA5456',
                left: `${styleProgress}%`,
              }}>
              {progress}%
            </span>
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: 'green',
                position: 'absolute',
              }}></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>0%</div>
            <div>100%</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => updateManualProgress()}>
            <MainButton text={'Done'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Show Error Msg*/}
      <Modal show={showErrorMsg} onHide={() => setShowErrorMsg(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <h5 className="text-center mt-4 mb-3">
            Activity can only be viewed within its date range
        </h5>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => setShowErrorMsg(false)}>
            <MainButton text={'Confirm'} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActivitiesTab;
